import type { ConstraintErrors, QueryParameters } from './urlParserUtils';
import {
  defaultConstraintErrors,
  arrivalDateParams,
  departureDateParams,
  validateAndFormatArrivalDepartureDates,
} from './urlParserUtils';

export type HiltonLinkUrlParser = {
  parsedQueryParams: QueryParameters;
  constraintErrors: ConstraintErrors;
};

export const hiltonlinkUrlParser = (
  nextRouterUrlParamsObject: Partial<QueryParameters> | Record<string, unknown>
): HiltonLinkUrlParser => {
  const parsedQueryParams: QueryParameters = {};

  const constraintErrors: ConstraintErrors = { ...defaultConstraintErrors };

  // next router object parsing
  Object.keys(nextRouterUrlParamsObject).forEach((param) => {
    const uriComponent: string = nextRouterUrlParamsObject[param] as string;
    const nextRouterUrlParamValue: string = decodeURIComponent(uriComponent);
    const paramStringValue: string = nextRouterUrlParamValue;

    if (param === 'ctyhocn') {
      return paramStringValue.match(/^[A-Z-]{4,7}$/)
        ? (parsedQueryParams.ctyhocn = paramStringValue)
        : (constraintErrors.ctyhocn = true);
    }

    if (param === 'groupCode') {
      return paramStringValue.match(/^[A-Z0-9]{2,7}$/)
        ? (parsedQueryParams.groupCode = paramStringValue)
        : (constraintErrors.groupCode = true);
    }

    if (param === 'step') {
      return (parsedQueryParams.step = paramStringValue);
    }

    if (param === 'edit') {
      return (parsedQueryParams.edit = paramStringValue === 'true');
    }

    if (param === 'userType') {
      return paramStringValue.match(/^(honors|corporate)$/)
        ? (parsedQueryParams.userType = paramStringValue)
        : (constraintErrors.userType = true);
    }

    if (param === 'showEventForm') {
      return (parsedQueryParams.showEventForm = paramStringValue === 'true');
    }

    if (arrivalDateParams.includes(param)) {
      return (parsedQueryParams.arrivalDate = paramStringValue);
    }

    if (departureDateParams.includes(param)) {
      return (parsedQueryParams.departureDate = paramStringValue);
    }

    if (param === 'event') {
      return paramStringValue.match(
        /^[A-Z]{5,7}-[0-9A-Z]{3}-[0-9A-F]{8}(?:-[0-9A-F]{4}){3}-[0-9A-F]{12}$|^[A-Z0-9-_]+$/i
      )
        ? (parsedQueryParams.event = paramStringValue)
        : (constraintErrors.event = true);
    }

    return null;
  });

  // validate arrivalDate and departureDate
  if (parsedQueryParams.arrivalDate && parsedQueryParams.departureDate) {
    validateAndFormatArrivalDepartureDates(parsedQueryParams, constraintErrors);
  } else {
    delete parsedQueryParams['arrivalDate'];
    delete parsedQueryParams['departureDate'];
    constraintErrors.arrivalDate = true;
    constraintErrors.departureDate = true;
  }

  return {
    parsedQueryParams,
    constraintErrors,
  };
};
