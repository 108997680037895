import { useContext } from 'react';
import type { FormErrorData, SocialShareData, LoginMetricsProps, LoginErrorProps } from '../lib';
import { MetricsContext } from '../lib';

type MetricsEventData =
  | FormErrorData
  | SocialShareData
  | LoginMetricsProps
  | LoginErrorProps; /* add the other event metrics props using | symbol */

export enum EventType {
  SIGN_IN_ONLY = 'sign-in-only',
  SIGN_OUT_ONLY = 'sign-out-only',
  LOG_IN = 'log-in',
  LOG_IN_ERROR = 'log-in-error',
  FORM_ERROR = 'form-error',
  SOCIAL_SHARE_CLICK = 'social-share-click',
}

export function useEventMetrics() {
  const { metrics } = useContext(MetricsContext);
  const setEventMetrics = (eventType: EventType, eventData?: MetricsEventData) => {
    /* eslint-disable-next-line default-case */
    switch (eventType) {
      case EventType.SIGN_OUT_ONLY: {
        metrics.setSignoutEvent();
        break;
      }
      case EventType.SIGN_IN_ONLY: {
        metrics.setLoginEvent();
        break;
      }
      case EventType.LOG_IN_ERROR: {
        metrics.setLoginError([eventData as LoginErrorProps]);
        break;
      }
      case EventType.LOG_IN: {
        metrics.setLoginUserData([eventData as LoginMetricsProps]);
        break;
      }
      case EventType.SOCIAL_SHARE_CLICK:
        metrics.setSocialShareData([eventData as SocialShareData]);
        break;
      case EventType.FORM_ERROR:
        metrics.setFormErrorData([eventData as FormErrorData]);
        break;
      /* add case for othe pages */
    }
  };

  return { setEventMetrics };
}
