import { useContext } from 'react';
import type {
  CommonDataProps,
  SignInPageMetricsProps,
  CustomPageMetricsProps,
  ConfirmationPageMetricsProps,
  GroupSearchPageMetricsProps,
  CreatePageMetricsProps,
  PreviewPageMetricsProps,
  ErrorPageMetricsProps,
} from '../lib';
import { MetricsContext } from '../lib';

export enum PageName {
  SIGN_IN = 'sign-in',
  CREATE = 'create',
  GROUP_SEARCH = 'group-search',
  CONFIRMATION = 'confirmation',
  PREVIEW = 'preview',
  CUSTOM_PAGE = 'custom-page',
  ERROR_PAGE = 'error-page',
}

type PageData<T> = T extends PageName.GROUP_SEARCH
  ? GroupSearchPageMetricsProps
  : T extends PageName.CREATE
  ? CreatePageMetricsProps
  : T extends PageName.PREVIEW
  ? PreviewPageMetricsProps
  : T extends PageName.CONFIRMATION
  ? ConfirmationPageMetricsProps
  : T extends PageName.CUSTOM_PAGE
  ? CustomPageMetricsProps
  : T extends PageName.SIGN_IN
  ? SignInPageMetricsProps
  : T extends PageName.ERROR_PAGE
  ? ErrorPageMetricsProps
  : never;

export function usePageMetrics() {
  const { metrics } = useContext(MetricsContext);

  const setPageMetrics = <T extends PageName>(
    pageName: T,
    commonData: CommonDataProps,
    pageData: PageData<T>
  ) => {
    metrics.setCommonData([commonData]);
    /* eslint-disable-next-line default-case */
    switch (pageName) {
      case PageName.GROUP_SEARCH:
        metrics.setGroupSearchPageData([pageData as PageData<PageName.GROUP_SEARCH>]);
        break;
      case PageName.CREATE:
        metrics.setCreatePageData([pageData as PageData<PageName.CREATE>]);
        break;
      case PageName.PREVIEW:
        metrics.setPreviewPageData([pageData as PageData<PageName.PREVIEW>]);
        break;
      case PageName.CONFIRMATION:
        metrics.setConfirmationPageData([pageData as PageData<PageName.CONFIRMATION>]);
        break;
      case PageName.CUSTOM_PAGE:
        metrics.setCustomGroupPageData([pageData as PageData<PageName.CUSTOM_PAGE>]);
        break;
      case PageName.SIGN_IN:
        metrics.setSignInPageData([pageData as PageData<PageName.SIGN_IN>]);
        break;
      case PageName.ERROR_PAGE:
        metrics.setErrorPageData([pageData as PageData<PageName.ERROR_PAGE>]);
        break;
      /* add case for other pages */
    }
  };

  return { setPageMetrics };
}
