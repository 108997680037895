import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { EventDocumentStatus } from '@dx-ui/gql-types';
import type {
  ContactFragmentFragment,
  ContentFragmentFragment,
  InfoFragmentFragment,
} from '../generated/types';
import { useRef, useEffect } from 'react';
import type { GraphQLError } from 'graphql';

export const EVENT_QUERY_KEY = 'EVENT';

export type EventProgress = {
  step: string;
  isEditingOrphanPage: boolean;
  isEditingPublishedPage: boolean;
  isUnauthorised: boolean;
  isRatePlanDatesChanged: boolean;
};

export type EventContent = NonNullable<ContentFragmentFragment>;

export type EventInfoRatePlans = NonNullable<InfoFragmentFragment>['ratePlans'];

export type EventEvent = {
  documentId?: string | null;
  documentStatus?: EventDocumentStatus | null;
  contact?: ContactFragmentFragment | null;
  content?: ContentFragmentFragment | null;
  info?: InfoFragmentFragment | null;
  progress?: EventProgress | null;
  error?: GraphQLError[];
} | null;

export type Event = {
  event: EventEvent;
};

const initialData = {
  event: null,
};

export function useEventData({ onCompleted }: { onCompleted?: (data: Event) => void } = {}) {
  const memoOnCompleted = useRef(onCompleted);
  const client = useQueryClient();
  const { data, isFetching } = useQuery<Event>({
    queryKey: [EVENT_QUERY_KEY],
    initialData,
  });

  useEffect(() => {
    memoOnCompleted.current?.(data);
  }, [data]);

  return [
    data,
    (state: Event) => {
      client.setQueryData([EVENT_QUERY_KEY], state);
    },
    { isFetching },
  ] as const;
}
